.rowT {
  display: flex;
  align-items: center;
  border-top: 2px solid rgb(228, 228, 228);
  padding: 10px;
  background: #fff;
}
.rowT img {
  width: 3vw;
  border-radius: 50%;
  border: 4px solid #00c2ff;
  margin-right: 1vw;
}
.rowT div {
  width: 13vw;
  margin-right: 2vw;
  font-size: 1vw;
}
.rowT:hover {
  background: rgba(0, 0, 0, 0.02);
  cursor: pointer;
}
.table {
  max-height: 80vh;
  overflow-y: scroll;
}
.addMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.addMessageBody {
  padding: 2vw;
  background: #fff;
  border-radius: 1vw;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.addMessageBody label {
  padding: 1vw;
  width: 100%;
  background: white;
  border: 1px solid #0094ff;
  color: #0094ff;
  text-align: center;
  border-radius: 0.5vw;
  margin-bottom: 1vw;
  margin-top: 0.5vw;
}
.sender {
  background: #0094ff;
  color: white;
  padding: 1vw 2vw;
  border-radius: 1vw;
  cursor: pointer;
  margin-left: 1vw;
}
.addMessageBody > * {
  width: 100%;
}
.addMessageBody h4 {
  text-align: center;
  font-size: 1.2vw;
  margin: 0.5vw 0;
}
.rowT {
  width: fit-content;
}
.frowT {
  background: #0094ff;
  color: #fff;
  border-radius: 1vw 1vw 0 0;
}
.frowT div {
  font-weight: bold;
}

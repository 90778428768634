.formActions input {
  padding: 1vw;
  border: 1px solid black;
  border-radius: 1vw;
  margin-bottom: 1vw;
  width: 100%;
  font-size: 0.8vw;
}
.popChange {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 222;
}
.popBodyChange {
  background: #fff;
  padding: 2vw;
  border-radius: 1vw;
}
.actionsCont {
  margin-right: 2vw;
}
.veb .panEm img {
  width: 2vw;
}
.veb .panel {
  top: 0;
  padding: 0;
  display: flex;
  align-items: center;
  padding: 0 1vw;
}
.veb {
  padding: 1vw;
  background: #fff;
  border-radius: 0.5vw;
  width: 20vw;
  margin-top: 1vw;
  position: relative;
}
.veb article {
  font-size: 1.5vw;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.5);
}
.radioB {
  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: 1vw;
}
.radioB label {
  font-size: 1vw;
  width: 20vw;
}
.radioB input {
  width: auto;
  margin-bottom: 0;
  margin-right: 0.5vw;
}

.messages div > div {
  padding: 1vw;
  width: fit-content;
  background: #b0d7e3;
  font-size: 0.8vw;
  margin-bottom: 0.5vw;
  border-radius: 1vw 0.5vw 0.5vw 0;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(34, 60, 80, 0.06);
  -moz-box-shadow: 0px 0px 7px 0px rgba(34, 60, 80, 0.06);
  box-shadow: 0px 0px 7px 0px rgba(34, 60, 80, 0.06);
  position: relative;
  padding-right: 3vw;
  max-width: 25vw;
}
.messages > div {
  display: flex;
}
.balls {
  margin-left: 2vw;
  width: 7vw !important;
}
.messages article p {
  text-align: center;
  font-size: 0.6vw;
}
.messages {
  max-height: 70vh;
  overflow-y: scroll;
}
.titleChat {
  font-size: 1.5vw;
  margin-bottom: 1vw;
}
.messages article {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1vw;
}
.messages div img {
  border-radius: 50%;

  width: 2.5vw;
  border: 2px solid #00c2ff;
  height: 2.5vw;
}
.messages div span {
  position: absolute;
  bottom: 0.2vw;
  right: 0.3vw;
  opacity: 0.4;
  font-size: 0.7vw;
}
.addMessageText {
  position: fixed;
  bottom: 0;
  padding: 1vw;
  background: #fff;
  width: 80vw;
  right: 0;
  display: flex;
  align-items: center;
}
.addMessageText input {
  padding: 0.5vw;
  border-radius: 0.5vw;
  border: 1px solid black;
  width: 30vw;
  font-size: 0.8vw;
}
.sendMessage {
  padding: 0.5vw 1vw;
  font-size: 0.8vw;
  background: #00c2ff;
  border-radius: 0.5vw;
  margin-left: 1vw;
  color: #fff;
  cursor: pointer;
}

.header {
  position: fixed;
  height: 7vw;
  padding: 2.3vw 3vw;
  display: flex;
  align-items: center;
  width: 80vw;
  left: 20vw;
  top: 0;
  justify-content: space-between;
  background: #fbfbfd;
  border-bottom: 1px solid #e8eaf1;
}
.title_str {
  font-size: 2vw;
  font-weight: bold;
  margin-right: 2vw;
  width: 12vw;
}
.header_button,
.dogovor {
  display: flex;
  align-items: center;
  font-size: 1vw;
  padding: 1vw;
  border-radius: 0.5vw;
  cursor: pointer;
  color: #496274;
}
.header_button p {
  margin-left: 0.3vw;
}
.header_button img {
  max-width: 1.5vw;
}
.dogovor {
  -webkit-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 21px 2px rgba(34, 60, 80, 0.15);
}

.header_button:hover {
  -webkit-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
}
.sale {
  background: #b6eeff;
}
.profile {
  width: 4vw;
  border-radius: 50%;
  margin-left: 5vw;
  border: 3px solid #b6eeff;
  cursor: pointer;
}

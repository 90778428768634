.navbar {
  width: 20vw;
  display: flex;
  flex-direction: column;
  padding: 3vw 2vw;
  background: #fff;
  border-right: 1px solid #e8eaf1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100vh;
  background: url(./fon.png);
  background-size: cover;
}
.navbar > img {
  width: 12vw;
  margin-bottom: 5vw;
}
.home {
  display: flex;
  align-items: center;
  color: #496274;
  font-weight: 700;
  font-size: 1vw;
  margin-bottom: 1.5vw;
  cursor: pointer;
}
.home img {
  width: 1.2vw;
  margin-right: 0.5vw;
}
.voice_assistant {
  display: flex;
  flex-direction: column;
  margin-top: 21vw;
}
.voice_assistant input {
  padding: 5px;
  border-radius: 10px;
  font-size: 1vw;
  border: 1px solid black;
  margin-bottom: 1vw;
}
.voice_assistant > * {
  width: 100%;
}
.voice_assistant:hover {
  transform: translateY(0px) !important;
}
.listen {
  background: #00c2ff;
  padding: 10px;
  margin-top: 1vw;
  text-align: center;
  border-radius: 5px;
  color: #fff;
}
.home_links li {
  display: flex;
  align-items: center;
  margin-bottom: 1.3vw;
  cursor: pointer;
  transition: 0.5s;
  color: #496274;
  font-size: 1vw;
}
.home_links li:hover {
  transform: translateY(-5px);
}
.home_links li:hover .circle {
  background: #496274;
}
.home_links {
  list-style-type: none;
  font-size: 0.9vw;
  margin-left: 0.5vw;
}
.home_links li .circle {
  display: block;
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
  background: #b0d7e3;
  margin-right: 1vw;
  cursor: pointer;
}

.addForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addForm input {
  padding: 0.5vw;
  border-radius: 0.5vw;
  border: 1px solid black;
  width: 20vw;
  margin-bottom: 1vw;
  font-size: 1vw;
}
.addForm h3 {
  text-transform: uppercase;
  margin-bottom: 1vw;
  font-size: 2vw;
}
.addCourse {
  background: black;
  width: 20vw;
  border-radius: 0.5vw;
  padding: 1vw;
  color: #fff;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;
}
.setCour {
  width: 40vw;
  background: #fff;
  padding: 2vw;
  margin-bottom: 1vw;
  border-radius: 0.5vw;
  font-size: 1vw;
  border: 1px solid #fff;
  font-weight: bold;
}
.panelRed {
  background: #fff;
  padding: 2vw;
  border-radius: 0.5vw;
  height: auto;
}
.panelRed p {
  font-size: 1vw;
  font-weight: bold;
  cursor: pointer;
}
.panelRed p:hover {
  color: darkturquoise;
}
.popaddCurse {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.popaddCurse .addForm {
  padding: 1vw;
  border-radius: 0.5vw;
  background: #fff;
}

.admin_enter_container {
  background: url(../imgs/admin1.png);
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
form {
  width: 100%;
}
canvas {
  height: 30vw;
}
.admin_enter_title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 3.5vw;
  color: #525252;
  width: 30vw;
  margin-right: 2vw;
  margin-bottom: 9vw;
}
.admin_register_title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 3.5vw;
  color: #525252;
  width: 30vw;
  margin-right: 2vw;
  margin-bottom: 15vw;
}
.admin_enter_inputs_white_background {
  background: #ffffff;
  border-radius: 2vw;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 0px 60px 0px rgba(0, 83, 139, 0.25);
}
.admin_enter_inputs_box {
  width: 28vw;
  position: relative;
  background: url(../imgs/adminEnterBackground.png);
  background-size: cover;
  border-radius: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5vw 2vw;
}
.admin_enter_inputs_box_register {
  width: 28vw;
  position: relative;
  background: url(../imgs/adminEnterBackground.png);
  background-size: cover;
  border-radius: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5vw 2vw;
}
.adminEnterBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.admin_author_register_box {
  display: flex;
  margin-bottom: 2vw;
  align-items: center;
  width: 100%;
}
.admin_author_register_box p {
  padding: 0.5vw 1.5vw;
  border: 0.2vw solid #00c2ff;
  font-size: 1.3vw;
  font-weight: 700;
  font-weight: 700;
  cursor: pointer;
}
.admin_author_register_box a {
  padding: 0.5vw 1.5vw;
  border: 0.2vw solid #00c2ff;
  font-size: 1.3vw;
  font-weight: 700;
  cursor: pointer;
}
.admin_author_register_box a {
  color: #525252;
}
.admin_author_register_box a:link {
  color: #525252;
}
.admin_author_register_box a:visited {
  color: #525252;
}
.admin_author_register_box a:hover {
  color: #525252;
}
.admin_author_register_box a:active {
  color: #525252;
}
#admin_choose_author {
  width: 50%;
  border-radius: 1vw 0 0 1vw;
}
#admin_choose_register {
  width: 50%;
  border-radius: 0 1vw 1vw 0;
}
.admin_chooser_active {
  background: #00c2ff;
  color: #ffffff;
}
.admin_chooser_disabled {
  background: #ffffff;
  color: #525252;
}
.admin_chooser_disabled:hover {
  background: rgba(0, 194, 255, 0.2);
}
.admin_enter_inputs_title {
  font-weight: 700;
  font-size: 1.55vw;
  margin-bottom: 1.5vw;
  color: #525252;
}
.admin_enter_phone_input_box {
  width: 100%;
  display: flex;
  border-radius: 1vw;
  margin-bottom: 1vw;
  position: relative;
}
.admin_enter_input_img_box {
  background: #c9f2ff;
  width: 3.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2vw;
  padding: 0.5vw 0.5vw;
  border-radius: 1vw 0 0 1vw;
}
.admin_enter_input_img {
  width: 1.2vw;
}
.AdminProfileIcon {
  width: 1.5vw;
}
.AdminPass {
  width: 1.5vw;
}
.Admin_company_icon {
  width: 1.8vw;
}
.admin_enter_inputs {
  border-radius: 0 1vw 1vw 0;
  outline: none;
  border: 0.1vw solid #e8e8e8;
  border-left: none;
  width: 100%;
  font-size: 1.1vw;
  padding-left: 1.5vw;
  font-weight: 700;
}
.admin_enter_button {
  width: 100%;
  border-radius: 1vw;
  border: none;
  background: #00c2ff;
  color: #ffffff;
  font-size: 1.2vw;
  padding: 0.7vw 0;
  cursor: pointer;
}
.comeback_team_box {
  display: flex;
  width: 20vw;
  margin-top: 3vw;
  align-items: center;
}
.comebackteamImg {
  width: 4vw;
  margin-right: 0.5vw;
}
.comeback_team_title {
  font-size: 1.9vw;
  line-height: 2vw;
}
.comeback_team_title span {
  font-weight: 700;
}
.right_part_admin {
  margin-top: 3vw;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.password_is_short {
  font-size: 0.8vw;
  color: red;
  position: absolute;
  top: -1vw;
  left: 3.2vw;
}
.input_checkbox_box {
  display: flex;
  width: 100%;
  margin-top: 1.5vw;
  padding-left: 1vw;
}
#scales {
  width: 1.2vw;
  background: none;
  margin-right: 0.5vw;
  outline: #00c2ff;
  border: #00c2ff 0.1vw solid;
}
.personal_agreement {
  text-decoration: underline;
  color: #00c2ff;
  cursor: pointer;
}
.label_for_input {
  font-size: 0.75vw;
}
.personal_agreement:link {
  color: #00c2ff;
}
.personal_agreement:visited {
  color: #00c2ff;
}
.personal_agreement:hover {
  color: #00c2ff;
}
.personal_agreement:active {
  color: #00c2ff;
}

.admin_dogovor_container {
  width: 100%;
  position: relative;
}
.dogovorMainImg {
  width: 100%;
}
.rekviziti {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 10vw;
  flex-direction: column;
  align-items: center;
}
.blue_rekvizit {
  background: #00c2ff;
  color: #ffffff;
}
.darkblue_rekvizit {
  background: #cee6ee;
}
.lightblue_rekvizit {
  background: #dbe2e4;
}
.rekvizit_text {
  font-size: 1.2vw;
  width: 45%;
  padding: 0.7vw 0;
  border-radius: 1vw;
  margin-bottom: 0.5vw;
}
@media screen and (max-width: 1024px) {
  .admin_enter_container {
    background: url(../imgs/admin1Mobile.png);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10vw;
  }
  .admin_enter_title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 8vw;
    color: #525252;
    text-align: left;
    align-self: flex-start;
    width: 50vw;
    padding-left: 5vw;
    margin-bottom: 9vw;
  }
  .admin_register_title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 8vw;
    color: #525252;
    text-align: left;
    align-self: flex-start;
    width: 50vw;
    padding-left: 5vw;
    margin-bottom: 9vw;
  }
  .right_part_admin {
    margin-top: 3vw;
    width: 100%;
    padding: 0 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .admin_enter_inputs_white_background {
    background: #ffffff;
    border-radius: 8vw;
    width: 100%;
    height: fit-content;
    box-shadow: 0px 0px 60px 0px rgba(0, 83, 139, 0.25);
  }
  .admin_enter_inputs_box {
    width: 100%;
    position: relative;
    background: url(../imgs/adminEnterBackground.png);
    background-size: cover;
    border-radius: 8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8vw 5vw;
  }
  .admin_enter_inputs_box_register {
    width: 100%;
    position: relative;
    background: url(../imgs/adminEnterBackground.png);
    background-size: cover;
    border-radius: 8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8vw 5vw;
  }
  .adminEnterBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .admin_author_register_box {
    display: flex;
    margin-bottom: 2vw;
    align-items: center;
    width: 100%;
  }
  .admin_author_register_box p {
    padding: 1vw 1.5vw;
    border: 1vw solid #00c2ff;
    font-size: 4.8vw;
    font-weight: 700;
    cursor: pointer;
  }
  .admin_author_register_box a {
    padding: 1vw 1.5vw;
    border: 1vw solid #00c2ff;
    font-size: 4.8vw;
    font-weight: 700;
    cursor: pointer;
  }
  #admin_choose_author {
    width: 51%;
    border-radius: 3vw 0 0 3vw;
  }
  #admin_choose_register {
    width: 51%;
    border-radius: 0 3vw 3vw 0;
  }
  .admin_enter_inputs_title {
    font-weight: 700;
    font-size: 5.2vw;
    margin: 4vw 0;
    color: #525252;
  }
  .admin_enter_phone_input_box {
    width: 100%;
    display: flex;
    border-radius: 1.5vw;
    margin-bottom: 5vw;
    position: relative;
  }
  .admin_enter_input_img_box {
    background: #c9f2ff;
    width: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 0.5vw 0.5vw;
    border-radius: 7vw 0 0 7vw;
  }
  .admin_enter_input_img {
    width: 3vw;
  }
  .AdminProfileIcon {
    width: 4.5vw;
  }
  .AdminPass {
    width: 4vw;
  }
  .Admin_company_icon {
    width: 5.5vw;
  }
  .admin_enter_inputs {
    border-radius: 0 7vw 7vw 0;
    outline: none;
    border: 0.1vw solid #e8e8e8;
    border-left: none;
    width: 100%;
    font-size: 4.1vw;
    padding-left: 4vw;
    font-weight: 700;
  }
  .admin_enter_inputs::placeholder {
    color: #acacac;
  }
  .admin_enter_button {
    width: 100%;
    border-radius: 7vw;
    border: none;
    background: #00c2ff;
    color: #ffffff;
    font-size: 4.1vw;
    padding: 2vw 0;
    cursor: pointer;
  }
  .comeback_team_box {
    display: flex;
    width: 40vw;
    margin-top: 5vw;
    align-items: center;
  }
  .comebackteamImg {
    width: 10vw;
    margin-right: 2vw;
  }
  .comeback_team_title {
    font-size: 4.2vw;
    line-height: 4vw;
    text-align: left;
  }
  .comeback_team_title span {
    font-weight: 700;
  }
  .password_is_short {
    font-size: 3vw;
    color: red;
    position: absolute;
    top: -4vw;
    left: 3.2vw;
  }
  .input_checkbox_box {
    display: flex;
    width: 100%;
    margin-top: 5vw;
    padding-left: 1vw;
  }
  #scales {
    width: 4vw;
    background: none;
    margin-right: 2vw;
    outline: #00c2ff;
    border: #00c2ff 0.1vw solid;
  }
  .personal_agreement {
    text-decoration: underline;
    color: #00c2ff;
  }
  .label_for_input {
    font-size: 2.8vw;
  }
}
.admin_main_page_container {
  width: 100%;
  height: 100%;
  position: relative;
}
.adminMainPageImg {
  width: 100%;
}
.hidden_button {
  width: 10.5vw;
  border-radius: 1vw;
  height: 3.2vw;
  position: absolute;
  top: 2.6vw;
  left: 27.2vw;
  cursor: pointer;
  border: 0.2vw solid #00c2ff;
  transform: scale(1);
  animation: hiddenWawes infinite 2s;
}
.hidden_button2 {
  width: 10.5vw;
  border-radius: 1vw;
  height: 3.2vw;
  position: absolute;
  top: 2.6vw;
  left: 27.2vw;
  cursor: pointer;
  pointer-events: none;
  border: 0.2vw solid #00c2ff;
  transform: scale(1);
  animation: hiddenWawes infinite 2s;
  animation-delay: 0.2s;
}
@keyframes hiddenWawes {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

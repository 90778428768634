.App {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.container {
  margin-left: 20vw;
  margin-top: 7vw;
  position: fixed;
  top: 0;
  left: 0;
}
body {
  background: #f1f6f7;
  width: 100vw;
  height: 100vh;
}
#root {
  background: #f1f6f7;
  width: 100vw;
  height: 100vh;
}
*::-webkit-scrollbar {
  width: 4px;
}
*::-webkit-scrollbar-track {
  background-color: #ffffff;
}
*::-webkit-scrollbar-thumb {
  background: #00c2ff;
  border-radius: 10px;
}
* {
  padding: 0;
  margin: 0;
  transition: 0.5s;
  font-family: "Mont";
  text-decoration: none;
  --big-button-disabled-background: #00c2ff;
  --big-button-active-background: #0094ff;
  --small-button-disabled-background: #ffffff;
  --small-button-active-background: #00c2ff;
  transition: 0.5s;
  box-sizing: border-box;
}
.button {
  padding: 1vw 4vw;
  cursor: pointer;
  border-radius: 10px;
  background: #0094ff;
  color: #fff;
  font-weight: bold;
  width: fit-content;
  font-size: 1vw;
  transition: 0.5s;
}
.button:hover {
  background: #00c2ff;
}
.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* background: url(./img/fon14.png); */
  background: #f1f6f7;
  /* background-position-x: -21vw;
  background-position-y: -19vw; */
  /* background-size: 100vw; */
}
a {
  color: #525252;
}
a:link {
  color: #525252;
}
a:visited {
  color: #525252;
}
a:hover {
  color: #525252;
}
a:active {
  color: #525252;
}
.sub_titles h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.5vw;
  margin-bottom: 0.5vw;

  color: #525252;
}
.subscribers {
  padding: 2vw 3vw;
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  width: 80vw;
}
.sub_titles * {
  text-align: left;
}
.sub_titles > p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  margin-bottom: 2vw;
  width: 22vw;
  color: #525252;
}
.sub_titles {
  margin-right: 5.3vw;
}
.xls {
  display: flex;
  align-items: center;
  font-family: "Mont";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  padding: 0.5vw 1.3vw;
  padding-right: 0.8vw;
  width: fit-content;
  /* identical to box height */
  background: #ffffff;
  box-shadow: 0px 0px 39px rgba(19, 44, 73, 0.15);
  border-radius: 0.5vw;
  cursor: pointer;
  color: #525252;
}
.xls img {
  margin-left: 0.8vw;
  width: 2vw;
}
.xls:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 48px rgba(19, 44, 73, 0.3);
}
.sub_total {
  display: flex;
  align-items: center;
}
.sub_total_num h3 {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5vw;
  line-height: 2vw;
  /* identical to box height */

  text-align: center;

  color: #000000;
}
.sub_total_num p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;

  /* identical to box height */

  text-align: center;

  color: #000000;
}
.sub_total_num {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
}
.sub_circle {
  width: 15vw;
  z-index: 5;
  position: relative;
  height: 15vw;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: #00c2ff;
  border: 8px solid rgba(196, 235, 248, 0.67);
  border-radius: 50%;
  overflow: hidden;
}
/* for the pen */

/* waves */

.sub_circle:after,
.sub_circle:before {
  content: "";
  position: absolute;

  left: 50%;
  min-width: 50vw;
  min-height: 50vw;
  background-color: #ffffff;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.sub_circle:before {
  bottom: 6vh;
  border-radius: 45%;
  animation-duration: 10s;
}

.sub_circle:after {
  bottom: 5vh;
  opacity: 0.5;
  border-radius: 47%;
  animation-duration: 10s;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}
.sub_description {
  background: #ffffff;
  box-shadow: 0px 0px 22px rgba(0, 83, 139, 0.12);
  padding: 1.2vw 2vw;
  padding-left: 4vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  transform: translateX(-2vw);
  position: relative;
}
.sub_description img {
  width: 3vw;
}
.date {
  margin-left: 0.6vw;
}
.date p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 1.4vw;

  /* identical to box height */

  color: #000000;
}
.date span {
  font-style: normal;
  font-weight: 600;
  font-size: 0.8vw;

  letter-spacing: 0.045em;

  color: #5b7384;
}
.date * {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sub_description .line {
  width: 0.6vw;
  height: 3.7vw;
  background: #00c2ff;
  border-radius: 2vw 0 0 2vw;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.days_gone {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5vw;
}
.days_gone .line {
  width: 6.5vw;
  height: 0.5vw;
  background: #00c2ff;
  border-radius: 2vw 2vw 0 0;
}
.days_gone_body {
  background: #ffffff;
  box-shadow: 0px 0px 22px rgba(0, 83, 139, 0.12);
  padding: 1.6vw 1.1vw;
  border-radius: 1vw;
}
.days_gone_body p {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9vw;
  max-width: 6vw;

  text-align: center;

  color: #000000;
}
.days_gone_body h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 3.2vw;
  line-height: 3.4vw;
  /* identical to box height */

  text-align: center;

  color: #00c2ff;
}
.raz {
  width: 80vw;
  position: fixed;
  bottom: -4vw;
  right: 0;
}
.pop_send {
  background: rgba(23, 42, 56, 0.27);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  top: 0;
  left: 0;
}
.pop_send_body {
  background: #fff;
  border-radius: 2vw;
  width: 32vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  position: absolute;
  bottom: 7vw;
  left: 4.5vw;
  background: url(./imgs/send_pop.png);
  background-size: cover;
  box-sizing: border-box;
}
.pop_send_body p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  text-align: center;
  width: 26vw;

  color: #525252;
}
.pop_send_body b {
  font-weight: 900;
}
.opacity_text {
  font-family: "Mont";
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  margin-top: 1vw;
  margin-bottom: 1.3vw;
  /* identical to box height */

  color: #acacac;
}
.input_text {
  width: 100%;
  font-size: 1vw;
  border-radius: 1vw;
  padding: 1vw;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  outline: none;
}
.save_send {
  background: #00c2ff;
  border-radius: 1vw;
  width: 100%;
  padding: 1vw;
  font-size: 1vw;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin-top: 1vw;
}

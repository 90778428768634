.popUpPos {
  position: fixed;
  top: 0;
  left: 0;
  width: 20vw;
  height: 100vh;
  background: #fff;
  padding: 1.5vw;
  z-index: 2222;
}
.popUpPos h4 {
  font-size: 1.5vw;
  margin-bottom: 2vw;
}
.popUpPos p {
  font-size: 1vw;
  margin-bottom: 0.5vw;
}
.popUpPos input {
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.5vw;
  border: 1px solid black;
  font-size: 0.8vw;
}
.newPos p {
  font-size: 0.8vw;
}
.newPos {
  padding: 0.5vw;
  transition: 0s;
  display: flex;
  align-items: center;
}
.newPos img {
  width: 2.5vw;
  margin-right: 1vw;
}
.newPos:hover {
  text-decoration: underline;
  cursor: pointer;
}

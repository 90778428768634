.orderElem {
  background: #fff;
  padding: 1vw;
  margin: 1vw 0;
  width: 100%;
  border-radius: 1vw;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
.ordersTab {
  padding: 15px !important;
}
.typesOrder {
  display: flex;
  align-items: center;
  font-size: 1vw;
}
.typesOrder div {
  width: auto;
  text-align: center;
  padding: 1vw 1.5vw;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.typesOrder div span {
  background: #0098c7;
  margin-left: 0.5vw;
  color: #fff;
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8vw;
}
.typesOrder div p {
  transition: 0.1s;
}
.typesOrder div p:hover {
  text-decoration: underline;
}
.text {
  font-size: 0.8vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.text p {
  margin-bottom: 0.3vw;
}
.ordersColumn {
  display: grid;
  grid-template-columns: 35vw 35vw;
  width: 75vw;
  padding: 1vw;
  padding-top: 0;
  overflow-y: scroll;
  max-height: 85vh;
  justify-content: space-between;
  align-items: start;
}
.status {
  width: fit-content;
  border: 1px solid black;
  padding: 0.5vw;
  border-radius: 0.5vw;
}
.status .statuses div,
.statusBody {
  padding: 0.3vw;
  cursor: pointer;
  font-size: 0.8vw;
  width: fit-content;
}
.orderButtons {
  margin-top: 1vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.messageOrder {
  background: #0098c7;
  color: #fff;
  padding: 0.5vw 1vw;
  border-radius: 0.3vw;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  cursor: pointer;
}
.sendMess {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 20000;
}
.sendMessBody {
  background: #fff;
  padding: 1vw;
}
.status:hover .statuses {
  display: block;
}
.statuses {
  display: none;
}
.statuses div:hover {
  transform: translateX(5px);
}
.titleOr {
  font-weight: bold;
  font-size: 1vw;
  margin-bottom: 0.5vw;
}
.ordersColumn h4 {
  text-align: center;
  font-size: 1vw;
}
.ordersColumn input {
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.5vw;
  border: 1px solid black;
  margin-top: 1vw;
}

.utc_main {
  background-size: cover;
  margin-left: 21vw;
  height: 100vh;
  padding-top: 56px;
  transition: 0.5s;
}
.utc_main * {
  font-family: "Mont-Bold";
}
.utc_main__table__header {
  background: #ab84fd;
  border-radius: 30px;
  padding: 30px;
  color: #fff;
  font-family: "Mont-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
.utc_main__table__header td:nth-child(1) {
  border-radius: 20px 0 0 20px;
}
.utc_main__table__header td:last-child {
  border-radius: 0 20px 20px 0;
}
.utc_main__table__header td {
  font-family: "Mont-Bold";
}
.utc_main__table__header td span {
  font-family: "Mont-Bold";
}
table td {
  padding: 20px;
  width: fit-content;
  max-width: 900px;
  font-family: "Mont-Bold";
}
.utc_main__table__main {
  max-height: 80vh;
  overflow-y: scroll;
  display: block;
  width: fit-content;
  border-radius: 1.5vw;
  margin-bottom: 2vw;
}
.utc_main__table__main::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
table {
  background: #fff;
  margin-left: 51px;
  margin: 0 auto;
  border-right: 1px solid #e8eaf1;
  box-shadow: 1px 6px 16px rgba(141, 85, 175, 0.25);
}
.utc_main__table__main__user__reflink {
  display: flex;
  align-items: center;
  width: auto;
}
.copy {
  margin-left: 10px;
}
.acc__active,
.copy {
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  padding: 9px;
  font-family: "Mont-Bold";
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.acc__disable {
  background: rgba(203, 182, 247, 0.46);
  border-radius: 30px;
  color: black;
  text-transform: uppercase;
  padding: 9px;
  font-family: "Mont-Bold";
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.utc_main__table__main__user__mavie,
.utc_main__table__main__user__reffio {
  max-width: 4vw;
}
table tr td:nth-child(2n-1) {
  background: rgba(171, 132, 253, 0.1);
}

table tr td:nth-child(1) {
  max-width: 200px;
}

table td {
  border-bottom: 3px solid rgba(158, 140, 196, 0.1);
  height: 6vw;
}

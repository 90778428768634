.search_services_first_section {
  font-weight: bold;
  font-size: 1.5vw;
  margin-top: 2vw;
}
.search_services_first_section:first {
  margin-top: 0;
}
.service_em {
  padding: 10px;
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.63);
  border-radius: 10px;
  margin-top: 1vw;
  position: relative;
  cursor: pointer;
  background: #fff;
}
.service_em .panel img {
  width: 2vw;
}
.services {
  width: 35vw;
  height: 80vh;
  overflow-y: scroll;
  padding: 1vw;
}
.service_price {
  font-weight: bold;
  color: rgba(0, 138, 237, 1);
  font-size: 1vw;
}
.service_name {
  font-weight: bold;
  font-size: 1vw;
}
.service_em .panel {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(19, 44, 73, 0.25);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 10px;
}
.service_em .panel p {
  font-size: 0.8vw;
}
.addServiceNew {
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.63);
  padding: 1.5vw;
  background: #fff;
  border-radius: 1vw;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addServiceNew h3 {
  font-size: 1.4vw;
  background: rgba(0, 138, 237, 1);
  color: #fff;
  padding: 1vw 2vw;
  border-radius: 0 0 1vw 1vw;
}
.addServiceNew input,
.addServiceNew textarea {
  outline: none;
  padding: 0.7vw;
  border-radius: 0.7vw;
  font-size: 0.8vw;
  border: 0.1vw solid gray;
  margin-top: 1vw;
  width: 100%;
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.63);
}
.addServiceNew .button {
  width: 100%;
  text-align: center;
  margin-top: 1vw;
  font-size: 0.8vw;
}
.category {
  outline: none;
  padding: 0.7vw;
  border-radius: 0.7vw;
  font-size: 0.8vw;
  border: 0.1vw solid gray;
  margin-top: 1vw;
  width: 100%;
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.63);
  cursor: pointer;
}
.categoryList div {
  padding: 5px 10px;

  color: rgba(0, 138, 237, 1);
  transition: 0.5s;
  cursor: pointer;
}
.categoryList div:hover {
  color: rgba(18, 57, 101, 0.95);
}
.categoryList {
  background: #fff;
  position: absolute;
  width: 100%;
  border-radius: 0.5vw;
  z-index: 3;
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.63);

  left: 0;
}
.search {
  width: 16vw;
}
.search input {
  outline: none;
  padding: 0.7vw;
  border-radius: 0.7vw;
  font-size: 0.8vw;
  border: 0.1vw solid gray;
  margin-top: 0.5vw;
  width: 100%;
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.63);
}
.search {
  text-align: center;
}
.search h3 {
  font-size: 1.2vw;
}

.leftRazdel {
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-bottom: 2vw;
  width: fit-content;
  grid-column-gap: 1.5vw;
  grid-row-gap: 1.5vw;
  overflow-y: scroll;
  height: 40vw;
  padding: 2vw;
}
.razdelbody {
  width: 100%;
}
.leftRazdel .block {
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1vw;
  font-size: 0.9vw;
}
.razdelbody {
  display: flex;
  justify-content: space-between;
}
.block div {
  background: #fff;
  width: 100%;
  padding: 0.5vw;
  border-radius: 1vw;
}
.block svg {
  width: 6vw;
}
.block h4 {
  text-transform: uppercase;
}
.block:nth-child(n) {
  background: #0068ff;
  filter: drop-shadow(0px 6px 25px rgba(0, 133, 255, 0.72));
}
.block:nth-child(2n) {
  background: #cf74ff;
  filter: drop-shadow(0px 6px 25px rgba(229, 126, 255, 0.72));
}
.block:nth-child(3n) {
  background: #f9c912;
  filter: drop-shadow(0px 6px 25px #ffe690);
}
.block:nth-child(4n) {
  background: #eb7170 !important;
  filter: drop-shadow(0px 6px 25px rgba(255, 138, 138, 0.72));
}

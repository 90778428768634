.notification {
  background: #fff;
  padding: 1.5vw;
  position: fixed;
  right: 2vw;
  top: -5vw;
  font-weight: bold;
  font-size: 1.2vw;
  border-radius: 0.5vw;

  transition: 0.7s;
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.83);
}

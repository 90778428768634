.zad li {
  font-size: 1.5vw;
  margin-bottom: 0.5vw;
  display: flex;
  align-items: center;
}
.zad li input {
  padding: 0.5vw;
  font-size: 1vw;
  margin-left: 1vw;
  border-radius: 0.5vw;
  border: 1px solid black;
}

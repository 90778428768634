.cardDoc {
  display: flex;
  align-items: center;
  padding: 1vw;
  background: #fff;
  border-radius: 1vw;
  margin-bottom: 1vw;
  width: 100%;
}
.cardDoc img {
  width: 10vw;
  margin-right: 1vw;
}
.doctors {
  height: 80vh;
  padding-right: 1vw;
  width: 41vw;
  overflow-y: scroll;
}
.cardDoc h4 {
  font-size: 1.2vw;
}
.cardDoc p {
  font-size: 1vw;
  margin-bottom: 0.5vw;
}
.cardDoc article {
  font-size: 0.8vw;
}
.cardDoc ul {
  display: flex;
}
.cardDoc li {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 0.5vw;
  font-size: 1vw;
}
.docFlex {
  display: flex;
  justify-content: space-between;
}
.formDoctor {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 1vw;
  border-radius: 1vw;
  padding-top: 0;
  height: fit-content;
  width: 18vw;
}
.formDoctor h4 {
  color: #fff;
  padding: 1vw 2vw;
  font-size: 1.2vw;
  background: #0094ff;
  border-radius: 0 0 0.5vw 0.5vw;
  margin-bottom: 2vw;
}
.formDoctor input,
.formDoctor textarea {
  border-radius: 0.5vw;
  padding: 0.5vw;
  font-size: 0.8vw;
  border: 0.1vw solid gray;
  width: 100%;
  outline: none;
  margin-bottom: 1vw;
  box-shadow: 0px 4px 17px 0px rgba(146, 210, 231, 0.63);
}
.formDoctor > .button {
  width: 100%;
  text-align: center;
}
.yous {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.1vw solid gray;
  width: 100%;
  border-radius: 1vw;
  padding: 1vw;
  margin-bottom: 1vw;
}
.yous p {
  font-size: 1vw;
  font-weight: bold;
}
.yous img {
  width: 3vw;
  height: 3vw;
  margin: 0.5vw 0;
}
.yous .button {
  width: 50%;
  font-size: 0.8vw;
  text-align: center;
  padding: 0.5vw 1vw;
}
